import React, { useEffect, useState } from 'react';

/**
 * @returns a controlled <input type="text" /> element that calls `onSubmit` when
 * losing focus (blur) and on 'Enter' key down.
 */
export default function TextInput({ value, onChange, onSubmit, disabled, placeholder, className }) {
  const [text, setText] = useState('');

  // when the passed in `value` changes, update the component
  useEffect(() => {
    setText(value ?? '');
  }, [value]);

  // the <input> is a controlled component;
  // activate the `onChange` callback on any text change
  function handleChange(e) {
    setText(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  }

  // activate the `onSubmit` callback when losing focus (onBlur)
  function handleBlur(e) {
    if (onSubmit) {
      onSubmit(text);
    }
  }

  // activate the `onSubmit` callback on keypress 'Enter'
  function handleKeyDown(e) {
    if (e.key === 'Enter' && onSubmit) {
      onSubmit(text);
    }
  }

  // select all text when focused
  function handleFocus(e) {
    e.target.select();
  }

  return <>
    <input value={text} 
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        onChange={handleChange} 
        onBlur={handleBlur} 
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
      />
  </>;
}
import { standardizeCardName } from '../helpers/card.helpers';
import { groupCardsBySet }     from '../helpers/card-pool.helpers'
import { 
  BonusSheetSetCodes, 
  PlayBoosterSetCodes, 
  RemasteredSetCodes 
} from '../constants';

const SCRYFALL_URI = 'https://api.scryfall.com'
const LOCAL_STORAGE_SET_RELEASE_DATES = 'set-release-dates';

const bent = require('bent')
const scryfallPOST = bent(SCRYFALL_URI, 'POST', 'json', 200);
const scryfallGET = bent(SCRYFALL_URI, 'json', 200);

/**
 * Custom Error type used for {@link Scryfall} errors.
 */
export class ScryfallError extends Error {
  constructor(message, options) {
    super(message, options);
    this.name = 'ScryfallError';
  }
}

export default class Scryfall {

  /**
   * Lazily initialized cache data for {@link latestFutureDraftSet}
   */
  static futureDraftSet = undefined;

  /**
   * Cache for {@link getReleaseDate}
   */
  static setReleaseDatePromises = {};

  /**
   * @returns an array containing data for all official draft sets
   */
  static fetchAllDraftSets() {
    // Don't show next set until previews are complete;
    // Previews end roughly two weeks before set release
    // (e.g., January 24, 2024 for February 9, 2024 release of MKM)
    // that example is actually 15 days, but WotC isn't always consistent;
    // conservatively use 14 days (2 weeks)
    const in2weeks = new Date();
    in2weeks.setDate(in2weeks.getDate() + 14);

    return scryfallGET(`/sets`)
      .then(json => {
        // the set types we care about
        const setTypes = new Set(['core', 'expansion', 'masters', 'alchemy', 'draft_innovation']);
        const sets = json.data.filter(s => {
          // convert UTC releasted_at date to a local date
          const releasedAtUTC = new Date(s.released_at);
          const releaseDateLocal = Scryfall.toLocalDate(releasedAtUTC);
          return (
            // release date is no more than two weeks away
            releaseDateLocal <= in2weeks &&
            // set is among those we care about
            setTypes.has(s.set_type) && 
            // and does not have a parent set code
            !s.parent_set_code &&
            // Scryfall Note: Official sets always have a three-letter set code, such as `zen`
            s.code.length === 3);
        });
        return sets;
      });
  }

  static scryfallIds(cards) {
    // extract the unique cards by name or name+set
    const distinctCardMap = cards.reduce(
      (map, card) => {
        const name = Scryfall.scryfallName(card);
        const key = card.set ? name + "+" + card.set.toUpperCase() : name;
        map[key] = card;
        return map;
      },
      /* initialValue = */ {}
    );

    const distinctCards = Object.values(distinctCardMap)
      // sort identifiers such that those without a `set` are first; otherwise
      // the Scryfall API responds with duplicates (instead of the newest edition)
      .sort((a, b) => a.set ? (b.set ? 0 : 1) : -1);

    // create the IDs for a scryfall query, specifying the card name and (optional) set
    return distinctCards.map(card => {
      const searchId = { name: Scryfall.scryfallName(card) };
      if (card.set) searchId.set = card.set;
      return searchId;
    });
  }

  static scryfallName(card) {
    // Expect a `card` intead of `cardName` so that the caller does not need 
    // to decide whether to pass the full card name or card front name.

    // Scryfall `/cards/collection` search uses only the card front name
    // but this _might_ not be a full card data object, so manually 
    // split on the forward slashes
    const cardName = standardizeCardName(card.name);
    const frontName = cardName.split("//")[0].trim();
    return frontName;
  }

  /**
   * Convert the given UTC date to a Date in local time.
   * 
   * @param {Date} dateUTC the date in UTC.
   * @returns the Date in local time.
   */
  static toLocalDate(dateUTC) {
    return new Date(
      // convert to local time by adding the time zone offset
      dateUTC.getTime() + (dateUTC.getTimezoneOffset() * 60000));
  }

  /**
   * @param {String} setCode the set code
   * @returns a {@link Promise} containing the set's official release date
   */
  static async getReleaseDate(setCode) {
    if (Object.keys(this.setReleaseDatePromises).length === 0) {
      // try to restore any stored set release dates
      Scryfall.restoreSetReleaseDates();
    }

    // check if a promise to load the release date already exists
    if (this.setReleaseDatePromises.hasOwnProperty(setCode)) {
      return  this.setReleaseDatePromises[setCode];
    }
    else {
      // fetch the set release date at https://api.scryfall.com/sets/woe
      const releaseDatePromise = scryfallGET(`/sets/${setCode.toLowerCase()}`)
        .then(json => {
          // JavaScript Date is always in UTC
          const releaseDateUTC = new Date(json.released_at);
          // update the cached set release dates in local storage
          Scryfall.cacheSetReleaseDate(setCode, releaseDateUTC);

          // convert UTC date to local date
          const releaseDateLocal = Scryfall.toLocalDate(releaseDateUTC);
          return releaseDateLocal;
        });
      this.setReleaseDatePromises[setCode] = releaseDatePromise;
      return releaseDatePromise;
    }
  }

  /**
   * Restore `Scryfall.setReleaseDatePromises` from local storage.
   */
  static restoreSetReleaseDates() {
    const releaseDatesJSON = localStorage.getItem(LOCAL_STORAGE_SET_RELEASE_DATES);

    // restore the release dates from local storage
    if (releaseDatesJSON) {
      const releaseDatesInMillis = JSON.parse(releaseDatesJSON);
      for (const [set, releaseDateMillis] of Object.entries(releaseDatesInMillis)) {
        // JavaScript Date is always in UTC
        const releaseDateUTC = new Date(Number(releaseDateMillis));
        // convert to local time by adding the time zone offset
        const releaseDateLocal = Scryfall.toLocalDate(releaseDateUTC);
        this.setReleaseDatePromises[set] = Promise.resolve(releaseDateLocal);
      }
    }
  }

  /**
   * Update the set release date in local storage, which is restored 
   * into `Scryfall.setReleaseDatePromises`.
   */
  static cacheSetReleaseDate(setCode, releaseDateUTC) {
    // retrieve the date values (millis) from localStorage
    const releaseDatesJSON = localStorage.getItem(LOCAL_STORAGE_SET_RELEASE_DATES);
    const releaseDatesInMillis = releaseDatesJSON ? JSON.parse(releaseDatesJSON) : {};

    // update the date value (millis) and store back to localStorage
    releaseDatesInMillis[setCode] = releaseDateUTC.valueOf();
    localStorage.setItem(LOCAL_STORAGE_SET_RELEASE_DATES,
      JSON.stringify(releaseDatesInMillis));
  }

  /**
   * Fetch all cards for a set from the Scryfall /cards/search API.
   * https://scryfall.com/docs/api/cards/search
   */
  static async fetchSet(setCode) {
    const set = setCode.toLowerCase();
    // Apply additional filters to cards from the set:
    // * filter out basic lands;
    // * only retrieve card available in booster boxes;
    // * include non-booster 'masters' set types (e.g., Shadows Over Innistrad Remastered)
    //   and explicitly exclude 'is:rebalanced' cards from these non-booster sets;
    const setQuery = `(set:${setCode}+-type:basic+(is:booster+OR+(st:masters+-is:rebalanced)))`;

    // special handling to adjust query to include bonus sheet sets
    let orBonusSetQuery = '';
    if (BonusSheetSetCodes.hasOwnProperty(set)) {
      const bonusSets = BonusSheetSetCodes[set].map(bonus => `set:${bonus}`).join('+OR+');
      // can't reliably use 'is:booster' for bonus sets (e.g., BIG), so explicitly
      // exclude 'is:rebalanced' cards from these non-booster sets
      // (e.g., 'A-Radha, Coalition Warlord')
      orBonusSetQuery = `+OR+((${bonusSets})+-is:rebalanced)`;
    }

    //TODO special handling for Alchemy (Y-prefixed) card sets

    return Scryfall.getSearchData(`/cards/search?q=${setQuery}${orBonusSetQuery}`);
  }

  /**
   * Get the response from the provided Scryfall URL path and parse the response 
   * for an error or one/more pages of card data.
   * 
   * @param {string} urlPath the relative Scryfall API URL path to retrieve
   * @param {Object[]} pendingCardData any pending card data to combine with the results of the response
   * @returns {Promise} a Promise with a result containing an array of the fetched card data.
   */
  static async getSearchData(urlPath, pendingCardData = [], getMore = true) {
    return this.attempt(2, // (with 2 retries on failure)
      () => scryfallGET(urlPath),
      // Scryfall Rate Limits and Good Citizenship:
      // We kindly ask that you insert 50 – 100 milliseconds of delay between the 
      // requests you send to the server. (i.e., 10 requests per second on average).
      100 // 100 milliseconds
    ).then(json => {
      // check for Scryfall errors
      if (json.object !== "list") {
        console.warn("Unexpected result from", urlPath, "-", json.details);
        return Promise.resolve([]);
      }

      // concatenate with the already-retrieved (pending) data
      const retrievedCardData = pendingCardData.concat(json.data);

      if (getMore && json.has_more) {
        const nextPage = json.next_page.replace(new RegExp(`^${SCRYFALL_URI}`), "");
        return Scryfall.getSearchData(nextPage, retrievedCardData);
      }
      else {
        return retrievedCardData;
      }
    }).catch(err => {
      
      // special error handling if we receive a json Promise
      if (err.json) return err.json().then(json => 
        Promise.reject(new ScryfallError(json.details, { cause: err }))
      );
      return Promise.reject(new ScryfallError(`Error fetching Scryfall data from ${SCRYFALL_URI}${urlPath}`, { cause: err }));
    });
  }

  /**
   * Fetch card data from Scryfall using the names of the provided cards.
   * 
   * @param {Object[]} cards the cards with a `name` (and optional `set`) property to use 
   * to search Scryfall for the corresponding card data.
   * @returns {Promise} a Promise with a result containing an array of the fetched card data.
   */
  static async fetchCards(cards) {
    //TODO if `cards` include a `scryfall_id` the card can be looked up directly
    const scryfallIds = Scryfall.scryfallIds(cards);
    return Scryfall.fetchCardCollection(scryfallIds);
  }

  /**
   * Fetch cards from the Scryfall /cards/collection API.
   * https://scryfall.com/docs/api/cards/collection
   */
  static async fetchCardCollection(scryfallCardIds, pendingCardData = []) {
    // A maximum of 75 card references may be submitted per request.
    const currentBatch = scryfallCardIds.slice(0, 75);
    const remainder = scryfallCardIds.slice(75, scryfallCardIds.length);

    // check if there is anything to fetch
    if (currentBatch.length === 0) {
      console.warn("no scryfall IDs to fetch");
      return Promise.resolve([]);
    }

    // otherwise, attempt to fetch a batch of card data
    return this.attempt(2, // (with 2 retries on failure)
      () => scryfallPOST('/cards/collection', { identifiers: currentBatch }),
      // Scryfall Rate Limits and Good Citizenship:
      // We kindly ask that you insert 50 – 100 milliseconds of delay between the 
      // requests you send to the server. (i.e., 10 requests per second on average).
      100 // 100 milliseconds
    ).then(json => {
      // check for Scryfall errors
      if (json.not_found && json.not_found.length) {
        console.warn("Some cards were not found", json.not_found);
      }

      // concatenate with the already-retrieved (pending) data
      const retrievedCardData = pendingCardData.concat(json.data);

      // more results to fetch?
      if (remainder.length) {
        return Scryfall.fetchCardCollection(remainder, retrievedCardData);
      } else {
        return retrievedCardData;
      }
    }).catch(err => {
      console.warn("Error fetching Scryfall data: ", err.message);
      return pendingCardData;
    });
  }

  /**
   * Recursively fetch cards from the ordered sets, narrowing by one set at a time.
   * 
   * @param {Object[]} cardsToRefresh an array of card objects each with a `name` property to try and refresh
   * @param {Object[]} allScryfallCards already-retrieved Scryfall card data to use to determine which 
   *  cards to NOT refetch per set (i.e., already have data for that card name/set)
   * @param {Number} commonSetMinimum the minimum number of cards needed to consider an already-retrieved 
   *  card set to be common enough to fetch additional cards
   * @param {string[]} orderedCardSets an optional array of card sets to fetch, in order of preference. This is 
   *  used to filter the common sets from `allScryfallCards`.  If a set is not determined to be a common set then 
   *  it will not be used to re-fetch any cards,
   * @returns {Promise} a Promise with a result containing an array of any fetched cards
   */
  static async fetchCardsForCommonSets(cardsToRefresh, allScryfallCards, commonSetMinimum, orderedCardSets) {
    if (!cardsToRefresh.length) {
      // exit early; nothing to fetch
      return Promise.resolve(allScryfallCards);
    }
    // `cardSetGroups` is cards fetched so far, not total/all metadata
    const cardSetGroups = groupCardsBySet(allScryfallCards);
    let orderedSets = Scryfall.orderedCardSets(cardSetGroups, commonSetMinimum);
    if (orderedCardSets) {
      // if provided, filter down to only those sets (maintaining the ordering)
      const setsToKeep = new Set(orderedCardSets);
      orderedSets = orderedSets.filter(set => setsToKeep.has(set));
    }

    if (orderedSets.length <= 0) {
      return Promise.resolve([]); 
    }

    // note that `undefined` is expected to be valid to retrieve "any set"
    const targetSet = orderedSets[0];

    // collect card names for this set that don't need to be fetched again
    const knownCardsForTargetSet = {};
    if (cardSetGroups.hasOwnProperty(targetSet)) {
      cardSetGroups[targetSet].forEach(scryfallCard => {
        // make sure there actually is Scryfall data for the provided card
        const scryfallName = Scryfall.scryfallName(scryfallCard);
        if (scryfallCard.scryfall_uri && !(scryfallName in knownCardsForTargetSet)) {
          knownCardsForTargetSet[scryfallName] = scryfallCard;
        }
      });
    }

    // extract the unique card names to fetch; skip already-known cards for the targetSet
    const cardNames = cardsToRefresh.reduce((set, card) => {
        const name = Scryfall.scryfallName(card);
        // skip cards that are already found for this set
        if (!(name in knownCardsForTargetSet)) {
          set.add(name);
        }
        return set;
      }, 
      /* initialValue = */ new Set());
    
    // create the IDs for a scryfall query, specifying the card name and set
    const scryfallIds = [];
    cardNames.forEach(name => scryfallIds.push({ name: name, set: targetSet }));
  
    if (scryfallIds.length <= 0) {
      // no fetching; just return the already-known cards for the target set
      return Promise.resolve(Object.values(knownCardsForTargetSet));
    }

    // actually fetch the cards
    const fetchCards = Scryfall.fetchCardCollection(scryfallIds);
    // calculate and fetch any remaining cards for the next ordered set
    const fetchRemaining = fetchCards.then(newCardsData => {
      // find card names that we didn't fetch for `targetSet`
      newCardsData.forEach(card => cardNames.delete(Scryfall.scryfallName(card)));
      const stillToFetch = [];
      cardNames.forEach(name => stillToFetch.push({ name: name }));
      // merge `newCardsData` to recursively pass all fetched Scryfall card data
      const allFetchedCards = Scryfall.mergeCards(allScryfallCards, newCardsData);
      return Scryfall.fetchCardsForCommonSets(stillToFetch, allFetchedCards, 
        commonSetMinimum, orderedSets.slice(1));
    });

    // complete all fetches and merge results with the already-known cards for this set
    return Promise.all([fetchCards, fetchRemaining])
      .then(([newCards, remainderCards]) => Scryfall.mergeCards(
        /* destination = */ Object.values(knownCardsForTargetSet), 
        /* sources = */ newCards, remainderCards));
  }

  static mergeCards(destination, ...sources) {
    const merged = [...destination];
    // flatten the sources array and then merge with the destination cards
    sources.flat().forEach(card => {
      // find and replace any card with the same name 
      // (we probably found better set-specific data)
      let found = false;
      for (let i = 0; i < merged.length; i++) {
        if (merged[i].name === card.name) {
          found = true;
          const original = merged[i];
          merged[i] = {...original, ...card};
        }
      }
      if (!found) {
        // add the new card to the retrieved cards
        merged.push(card);
      }
    });
    return merged;
  }

  static async attempt(retries, fn, delay) {
    // wait first, then attempt the function; retry n times after delay
    return Scryfall.wait(delay).then(fn).catch(err =>
      (retries > 0) ? Scryfall.attempt(retries - 1, fn, delay) : Promise.reject(err)
     );
  }
  
  static async wait(time) {
    return new Promise(resolve => setTimeout(resolve, time || 0));
  }

  //TODO move this into CardPoolScryfallData?
  static orderedCardSets(cardSetGroups, minimumCount = 2) {
    const allSets = Object.keys(cardSetGroups);
    const orderedSets = allSets
      // only return sets that are found among the minimum number of cards (no less than 2)
      .filter(set => cardSetGroups[set].length >= Math.max(2, minimumCount))
      // sort sets by number of cards in group, largest first
      .sort((a, b) => cardSetGroups[b].length - cardSetGroups[a].length);
  
    // helper method to directly insert sets within `orderedSets`
    const maybeInsertSetAfter = (findSets, ...insertSets) => {
      // filter existing orderedSets out of insertSets
      const setsToInsert = insertSets.flat().filter(set => !orderedSets.includes(set));
      // exit early if no sets to insert
      if (!setsToInsert.length) return;

      // find the *first* index of any findSets[]
      const setIndex = orderedSets.findIndex(set => findSets.includes(set));
      if (setIndex >= 0) {
        // insert sets after the first base set (and remove none)
        orderedSets.splice(setIndex+1, 0, ...setsToInsert)
      }
    }

    // Append Special Guests ('spg') and The List ('plst') after the *first* majority set that
    // uses Play Boosters. Insert directly after that sets (before other sets, Play Booster or
    // otherwise).  It's the largest majority set and seems more likely to be the set that
    // 'The List' should apply to.  For example, inserting 'The List' directly after MH3 is 
    // better when MH2 cards outnumber MKM:  http://sizzle.sealeddeck.tech/OG1934hOFH
    //   MH3 > [PLST > SPG] > MH2 > MKM 
    //   MH3 > MH2 > MKM > [PLST > SPG]
    // See https://mtg.fandom.com/wiki/Special_Guests
    // Note: LCI did have Special Guest cards, but is not included here because they were not 
    // included in Draft Boosters (Play Boosters did not start until MKM).
    // Insert SPG first; example: Dismember for MH3 - https://scryfall.com/card/spg/41/dismember
    maybeInsertSetAfter(PlayBoosterSetCodes, 'spg', 'plst');
    
    // special handling to insert bonus sheet set codes with standard set code
    for (const [setCode, bonusSetCodes] of Object.entries(BonusSheetSetCodes)) {
      const setsToFind = [setCode];
      // check for remastered sets that have a bonus sheet
      // (e.g., insert SIS when sets are SOI and/or EMN in addition to SIR)
      if (RemasteredSetCodes.hasOwnProperty(setCode)) {
        setsToFind.push(...RemasteredSetCodes[setCode]);
      }
      maybeInsertSetAfter(setsToFind, bonusSetCodes);
    }

    // special handling for HBG -> always order HBG (Alchemy Horizons: Baldur's Gate) before 
    // both AFR and CLB otherwise Scryfall fetches those non-Alchemy sets by default
    const hbgIndex = orderedSets.indexOf('hbg');
    if (hbgIndex >= 0) {
      const afrIndex = orderedSets.indexOf('afr');
      const clbIndex = orderedSets.indexOf('clb');
      const first = Math.min(hbgIndex,
        afrIndex >= 0 ? afrIndex : hbgIndex,
        clbIndex >= 0 ? clbIndex : hbgIndex);
      // move HBG to the first of these indexes, pushing AFR/CLB down in order
      orderedSets.splice(first, 0, orderedSets.splice(hbgIndex, 1)[0]);
    }
  
    return orderedSets;
  }
  
}